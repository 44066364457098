import { EventWithExistQuery_event_currentAnswers } from 'generated/EventWithExistQuery';

interface AnswerToQuestion {
  questionId: string;
  answerText: string;
}

export type CurrentAnswers = EventWithExistQuery_event_currentAnswers;

export const getAnswerToQuestions = <T extends Record<string, any>>(formResult: T): AnswerToQuestion[] => {
  return Object.keys(formResult)
    .filter((key) => key.startsWith('question-'))
    .map((key) => ({
      questionId: key.split('-')[1],
      answerText: formResult[key as keyof T] as string,
    }));
};
