import * as F from 'shared/shared/Functional';
import * as m from 'models';
import { CreateEventVariables } from 'generated/CreateEvent';
import { CurrencyType, PriceType } from 'shared/shared/types';
import { DEFAULT_IMAGE_URL } from './constants';
import { EventFormT } from './types';
import { EventType } from 'components/EventCreateUpdateForm/EventTypeWidget/EventTypeWidget';
import { QuestionList } from 'components/EventCreateUpdateForm/QuestionsWidget/QuestionWidget';
import { TicketTypes } from 'components/EventCreateUpdateForm/TicketTypesWidget/TicketTypesWidget';
import { eventToIWhen } from 'components/EventCreateUpdateForm/WhenWidget/WhenWidget';
import { eventToIWhere } from 'components/EventCreateUpdateForm/WhereWidget/WhereWidget';
import { getDefaultTimezone } from 'utils/datetime';
import { sortByPosition } from 'utils/helpers';
import moment from 'moment';

const whenToEvent = ({ when }: EventFormT) => {
  const { start, timezone, end, poll } = when;
  return {
    startTime: start ? moment.tz(start, timezone!).toISOString() : null,
    timezone: timezone || getDefaultTimezone(),
    endTime: end ? moment.tz(end, timezone!).toISOString() : null,
    poll: poll ? poll.options.map((o: any) => o.text) : null,
  };
};

const whereToEvent = ({ where }: EventFormT) => {
  const { location, locationReleaseTimeDelta } = where;
  return {
    location,
    locationReleaseTimeDelta: locationReleaseTimeDelta ? locationReleaseTimeDelta.asMilliseconds() : null,
  };
};

export const defaultQuestions = [
  {
    required: false,
    question: '',
  },
];

const eventToQuestions = (event?: m.Event) => {
  if (!event?.questions) {
    return [
      {
        required: false,
        question: '',
      },
    ];
  }

  return event?.questions?.map((question) => {
    return {
      id: question.id,
      required: question.isRequired,
      question: question.questionText,
    };
  }) as QuestionList;
};

const questionsToEvent = ({ options }: EventFormT) => {
  return (
    options?.questions
      ?.map((q: any, index: number) => {
        return {
          id: q.id,
          isRequired: q.required,
          position: index,
          questionText: q.question,
        };
      })
      .filter((q: any) => q.questionText?.trim()) || []
  );
};

const eventToTickets = (event?: m.Event) => {
  if (!event?.ticketTypes) {
    return [
      {
        priceType: PriceType.suggested,
        description: '',
        suggestedDonationMinCents: 0,
        suggestedDonationDefaultCents: 0,
      },
    ] as TicketTypes;
  }

  const ticketTypes = event.ticketTypes.slice();
  return ticketTypes.sort(sortByPosition).map((ticketType) => {
    return {
      id: ticketType.id,
      name: ticketType.name,
      priceType: typeof ticketType.fixedPriceCents === 'number' ? PriceType.fixed : PriceType.suggested,
      description: ticketType.description,
      suggestedDonationMinCents: ticketType.suggestedDonationMinCents,
      suggestedDonationDefaultCents: ticketType.suggestedDonationDefaultCents,
      fixedPriceCents: ticketType.fixedPriceCents,
      capacity: ticketType.capacity,
    };
  }) as TicketTypes;
};

const ticketsToEvent = (formValues: EventFormT) => {
  return formValues.eventType === EventType.FREE
    ? null
    : formValues.ticketTypes?.map((tt: any, index: number) => {
        return {
          ...tt,
          position: index,
        };
      });
};

const eventToOptions = (event?: m.Event) => {
  return {
    captureGuestPhone: event?.captureGuestPhone ?? false,
    guestsCanInvite: event?.guestsCanInvite ?? true,
    maxCapacity: event?.maxCapacity ?? null,
    maxPlusN: event?.maxPlusN ?? 0,
    password: event?.password ?? undefined,
    privacy: event?.privacy ?? 'U',
    questions: eventToQuestions(event),
    registrationEndTime: event?.registrationEndTime?.toObject() ?? undefined,
    showGuestList: event?.showGuestList ?? false,
    showTicketAvailability: event?.showTicketAvailability ?? false,
  };
};

const optionsToEvent = (formValues: EventFormT) => {
  if (!formValues.options) {
    return undefined;
  }
  const {
    captureGuestPhone,
    guestsCanInvite,
    maxCapacity,
    maxPlusN,
    password,
    privacy,
    registrationEndTime,
    showGuestList,
    showTicketAvailability,
  } = formValues.options;
  return {
    captureGuestPhone: !!captureGuestPhone,
    guestsCanInvite,
    maxCapacity,
    maxPlusN,
    password,
    privacy,
    questions: questionsToEvent(formValues),
    showGuestList,
    showTicketAvailability,
    registrationEndTime: registrationEndTime
      ? moment.tz(registrationEndTime, formValues.when.timezone!).toISOString()
      : null,
  };
};

const eventToAdvancedOptions = (event?: m.Event) => {
  return {
    hostedByOverride: event?.hostedByOverride ?? '',
    disableComments: event?.disableComments ?? false,
    facebookPixelId: event?.facebookPixelId ?? '',
  };
};

const advancedOptionsToEvent = (formValues: EventFormT) => {
  if (!formValues.advancedOptions) {
    return undefined;
  }
  const { hostedByOverride, disableComments, facebookPixelId } = formValues.advancedOptions;
  return {
    hostedByOverride: hostedByOverride ?? '',
    disableComments: !!disableComments,
    facebookPixelId: facebookPixelId || undefined,
  };
};

export const getDefaultValues = (event?: m.Event, initialTitle?: string): EventFormT => {
  return {
    confirmedGuestMessage: event?.confirmedGuestMessage ?? '',
    currency: event?.currency ?? CurrencyType.USD,
    description: event?.description ?? '',
    eventType: !event?.ticketTypes ? EventType.FREE : EventType.TICKETED,
    themePic: event?.themePic ?? DEFAULT_IMAGE_URL,
    shouldSendConfirmation: false,
    ticketTypes: eventToTickets(event),
    title: event?.title ?? initialTitle ?? '',
    when: eventToIWhen(event),
    where: eventToIWhere(event),
    discountCodes: event?.discountCodes
      ? event?.discountCodes.map((discountCode: any) => ({
          ...F.pick(discountCode, 'id', 'code', 'type', 'amount', 'isActive', 'quantityClaimed'),
        }))
      : [],
    options: eventToOptions(event),
    advancedOptions: eventToAdvancedOptions(event),
  };
};

export const eventFormToVariables = (formData: EventFormT): CreateEventVariables => {
  return {
    captureGuestPhone: false,
    disableComments: true,
    guestsCanInvite: false,
    showGuestList: true,
    showTicketAvailability: false,
    ...whenToEvent(formData),
    ...whereToEvent(formData),
    ...formData,
    ...optionsToEvent(formData),
    ...advancedOptionsToEvent(formData),
    shouldSendConfirmation: formData.shouldSendConfirmation,
    ticketTypes: ticketsToEvent(formData),
    discountCodes: formData.discountCodes
      ? formData.discountCodes.map((discountCode: any) => ({
          ...F.pick(discountCode, 'id', 'code', 'type', 'amount', 'isActive'),
        }))
      : null,
  };
};
