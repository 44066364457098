import React, { useEffect } from 'react';

import OptionSwitch from 'components/OptionSwitch/OptionSwitch';
import TextArea2 from 'components/common/TextArea2';

import { WidgetProps } from 'components/widgets';
import { useForm } from 'react-hook-form';

export type QuestionList = QuestionSettings[];

export interface QuestionSettings {
  id?: string;
  question: string;
  required: boolean;
}

type FormT = {
  question: string;
  required: boolean;
};

interface Attrs {}

const getDefaultValues = (question: QuestionSettings): FormT => {
  return {
    question: question?.question ?? '',
    required: question?.required ?? false,
  };
};

const QuestionWidget = (props: WidgetProps<QuestionSettings, Attrs>) => {
  const { disabled, value: question, onChange } = props;
  const defaultValues = getDefaultValues(question);
  const formContext = useForm<FormT>({ defaultValues });
  const { watch, register } = formContext;

  const { question: questionValue } = watch();

  useEffect(() => {
    onChange({ ...question, question: watch().question });
  }, [questionValue]);

  return (
    <div className="w-full max-w-3xl px-5 pt-10 pb-4 bg-white border border-gray-400 border-solid rounded-md">
      <TextArea2
        name="question"
        ref={register}
        placeholder="Type your question here (e.g., ‘Do you have any dietary restrictions?’)"
      />
      <div className="mt-2 ml-2">
        <OptionSwitch
          checked={question.required}
          fieldName="required"
          title="Required"
          onChange={(e, checked) => onChange({ ...question, required: checked })}
        />
        {disabled && (
          <div className="mt-1 text-sm italic text-gray-600">
            Questions cannot be deleted once they have been answered by any guest
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionWidget;
