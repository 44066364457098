import AddButton from 'components/common/AddButton/AddButton';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import QuestionsWidgetWrapper from 'components/EventCreateUpdateForm/QuestionsWidget/QuestionWidgetWrapper';
import React from 'react';

import { CurrentAnswers } from 'models/answers';
import { EventFormContext } from 'components/EventCreateUpdateForm/EventCreateUpdateForm/types';
import { QuestionSettings } from 'components/EventCreateUpdateForm/QuestionsWidget/QuestionWidget';
import { WidgetProps } from 'components/widgets';
import { defaultQuestions } from 'components/EventCreateUpdateForm/EventCreateUpdateForm/helpers';

interface Props {
  mxForm: EventFormContext;
  currentAnswers?: CurrentAnswers[] | null;
}

export type QuestionSettingsList = QuestionSettings[];

interface Attrs {
  answeredQuestionsIds: string[];
}

const QuestionFields = ({ currentAnswers, mxForm }: Props) => {
  const { formMethods, isSubmitting, onChange } = mxForm;
  const { watch } = formMethods;

  const options = watch('options');
  const questions: QuestionSettings[] = watch('options')?.questions;
  const answeredQuestionsIds = currentAnswers?.map((c) => c.questionId) || [];

  return (
    <div>
      <FieldLayout error={formMethods.errors.options?.questions as any}>
        <QuestionsWidget
          onChange={(val) => onChange('options', { ...options, questions: val })}
          value={questions}
          disabled={isSubmitting}
          attrs={{
            answeredQuestionsIds,
          }}
        />
      </FieldLayout>
    </div>
  );
};

const QuestionsWidget: React.FC<WidgetProps<QuestionSettingsList, Attrs>> = (props) => {
  const { value: dbQuestions, onChange, attrs } = props;
  const { answeredQuestionsIds } = attrs!;

  const questions = dbQuestions?.length > 0 ? dbQuestions : defaultQuestions;

  function onHandleQuestionChange(index: number, value: QuestionSettings) {
    const newQuestions = questions.slice();
    newQuestions[index] = value;
    onChange(newQuestions);
  }

  function onDeleteQuestion(index: number) {
    const newQuestions = questions.slice(0, index).concat(questions.slice(index + 1));
    onChange(newQuestions);
  }

  function onAddQuestion() {
    const prevType = questions[questions.length - 1];
    const newQuestion: QuestionSettings = {
      ...prevType,
      question: '',
      required: false,
    };
    delete newQuestion['id'];
    const newQuestions = questions.concat(newQuestion);
    onChange(newQuestions);
  }

  function onMoveUp(index: number) {
    const newQuestions = questions.slice();
    const temp = newQuestions[index - 1];
    newQuestions[index - 1] = newQuestions[index];
    newQuestions[index] = temp;
    onChange(newQuestions);
  }

  function onMoveDown(index: number) {
    const newQuestions = questions.slice();
    const temp = newQuestions[index + 1];
    newQuestions[index + 1] = newQuestions[index];
    newQuestions[index] = temp;
    onChange(newQuestions);
  }

  return (
    <div className="vstack-small">
      {questions.map((q: QuestionSettings, index: number) => (
        <QuestionsWidgetWrapper
          disabled={!!(q.id && answeredQuestionsIds.includes(q.id))}
          hasMany={questions.length > 1}
          isFirst={!index}
          isLast={index === questions.length - 1}
          key={q.id || index}
          onChange={(value: any) => onHandleQuestionChange(index, value)}
          onDelete={() => onDeleteQuestion(index)}
          onMoveDown={() => onMoveDown(index)}
          onMoveUp={() => onMoveUp(index)}
          value={q}
        />
      ))}
      <AddButton onClick={onAddQuestion}>Add another question</AddButton>
    </div>
  );
};

export { QuestionFields };
