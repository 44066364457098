import { COLUMNS, EventSummary } from './config';
import { EVENT_SUMMARIES_FOR_PERSON_QUERY } from 'utils/gql';
import { EventSummariesForPerson } from 'generated/EventSummariesForPerson';
import { URLContext } from 'components/RoutingProvider';
import { reverse } from 'shared/shared/routing/mixily-routes';
import { useQuery } from '@apollo/client';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import DashboardTable from 'components/pages/Dashboard/DashboardTable/DashboardTable';
import LoginRequired from 'components/LoginRequired';
import React, { useContext } from 'react';

const EventsDashboardPage = () => {
  const { setPath } = useContext(URLContext);
  const { data: summaries, loading } = useQuery<EventSummariesForPerson>(EVENT_SUMMARIES_FOR_PERSON_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const data = summaries?.me?.eventSummaries || [];

  return (
    <LoginRequired>
      <BaseLayout title="Dashboard">
        <DashboardTable<EventSummary>
          data={data}
          columns={COLUMNS}
          loading={loading}
          onClickRow={(row) => setPath(reverse('dashboard_event', { id: row.original.id }))}
          dateField="startTime"
          type="events"
        />
      </BaseLayout>
    </LoginRequired>
  );
};

export default EventsDashboardPage;
