import { Currency } from 'shared/shared/types';
import { WidgetProps } from 'components/widgets';
import QuantityDropdown from 'components/common/QuantityDropdown/QuantityDropdown';
import React from 'react';
import TicketPriceWidget from './TicketPriceWidget/TicketPriceWidget';

export interface TicketPriceAndQuantityValue {
  price: number | null;
  quantity: number;
}

interface Attrs {
  currency: Currency;
  fixedPrice: boolean;
  minQuantity?: number;
  maxQuantity?: number;
}

const TicketPriceAndQuantityWidget: React.FC<WidgetProps<TicketPriceAndQuantityValue, Attrs>> = (props) => {
  const { value, disabled, onChange, attrs } = props;
  const { price, quantity } = value;
  const { currency, fixedPrice, minQuantity, maxQuantity } = attrs!;

  function handleChangeTicketPrice(price: number | null) {
    onChange({ ...value, price });
  }

  function handleChangeQuantity(quantity: number) {
    onChange({ ...value, quantity });
  }

  return (
    <div className="flex flex-row items-center">
      <div className="flex-grow">
        <label className="text-sm font-bold tracking-wide uppercase">
          {fixedPrice ? 'Ticket Price' : 'Contribution Amount'}
        </label>
        <TicketPriceWidget
          value={price}
          onChange={handleChangeTicketPrice}
          disabled={disabled}
          attrs={{
            readOnly: fixedPrice,
            className: 'w-full mt-1 px-2 py-1 border rounded shadow-sm focus:ring focus:ring-indigo-500',
            currency,
          }}
        />
      </div>
      <div className="flex-shrink-0 w-16 ml-2 text-center">
        <label className="text-sm font-bold tracking-wide uppercase">Qty</label>
        <QuantityDropdown
          value={quantity}
          onChange={handleChangeQuantity}
          disabled={disabled}
          attrs={{
            min: minQuantity,
            max: maxQuantity,
          }}
        />
      </div>
    </div>
  );
};

export default TicketPriceAndQuantityWidget;
