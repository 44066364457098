import Container from 'components/layout/Container/Container';
import Footer from 'components/layout/Footer/Footer';
import Header from 'components/layout/Header/Header';
import React from 'react';
import RootLayout from 'components/layout/RootLayout/RootLayout';

import ghostChat from './homepage-ghost.svg';
import guestIllustration from './guest.svg';
import handRsvp from './homepage-rsvp.svg';
import wormCal from './homepage-worm.svg';

import { Button, Link } from 'components/common';
import { reverse } from 'shared/shared/routing/mixily-routes';
import origami from './origami.png';
import tickets from './tickets.png';

import Testimonials from 'components/pages/LandingPage/Testimonials/Testimonials3';

import MiniFeature from 'components/pages/LandingPage/MiniFeature';
import hands from './hands.svg';

const newOrange = '#EF9071';
const newGray = '#665F5D';

const LandingPage = () => {
  return (
    <RootLayout title="Mixily">
      <div className="py-3 text-center text-white" style={{ backgroundColor: newOrange }}>
        <a
          href="https://www.producthunt.com/posts/virtual-venue-by-mixily"
          rel="noreferrer"
          target="_blank"
          className="font-bold text-white"
        >
          🎉 #1 Product of the day on Product Hunt 🎉
        </a>
      </div>

      <Header landingPage={true} withSidebar={false} usePermanentSidebar={false} />
      <Container>
        <div className="mt-8 text-2xl text-center lg:font-light md:text-5xl lg:text-6xl">
          All the event hosting basics
          <br />
          you know and need.
          <br />
          Plus ticketing.
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            <MiniFeature
              title="Event ticketing made easy"
              imgSrc={wormCal}
              subText="Stripe integration, suggested donations, promo codes, clean UI"
            />
            <MiniFeature
              title="Every guestlist tool you depend on"
              imgSrc={ghostChat}
              subText="gather rsvps, schedule invitation & reminders, etc."
            />
            <MiniFeature
              title="Beautiful event landing pages"
              imgSrc={handRsvp}
              subText="Sophisticated, clean design for every kind of event"
            />
          </div>
        </div>

        <div className="my-20 text-center">
          <Button
            color="custom"
            href={reverse('account_signup')}
            className="px-6 py-2 mx-auto text-xl font-extrabold text-white uppercase rounded-md focus:outline-none md:text-2xl lg:text-3xl"
            label="Sign up for free"
            style={{ backgroundColor: '#ef8f71' }}
          />
        </div>
      </Container>

      <div style={{ backgroundColor: newGray }} className="text-white">
        <Container>
          <div className="pt-20 text-5xl font-bold text-center">Sell tickets</div>

          <div className="flex flex-col items-center justify-center mt-20 lg:flex-row">
            <div className="max-w-2xl">
              <img src={tickets} alt="Example ticket sales configuration" loading="lazy" />
            </div>

            <div className="max-w-2xl mt-16 lg:ml-16 lg:mt-0">
              <div>
                <ul className="text-xl">
                  <li>Create ticketing tiers</li>
                  <li className="mt-4">Connect your bank account with Stripe</li>
                  <li className="mt-4">Create promo codes</li>
                  <li className="mt-4">Get payouts before your event</li>
                  <li className="mt-4">Track your ticket sales across events with the Revenue Dashboard</li>
                </ul>
              </div>

              <div className="mt-16">
                <div>
                  <Button
                    color="custom"
                    href={reverse('event_create')}
                    className="px-6 py-2 mx-auto text-xl font-extrabold text-white uppercase rounded-md focus:outline-none md:text-2xl lg:text-3xl"
                    label="START SELLING TICKETS"
                    style={{ backgroundColor: '#ef8f71' }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-20">&nbsp;</div>
        </Container>
      </div>

      <div>
        <Container>
          <div className="pt-20 text-5xl font-bold text-center">Event landing pages</div>

          <div className="flex flex-col items-center justify-center mt-20 lg:flex-row">
            <div className="w-1/2 lg:max-w-2xl">
              <img src={guestIllustration} alt="Guests" className="w-full" loading="lazy" />
            </div>

            <div className="max-w-2xl mt-16 lg:ml-16 lg:mt-0">
              <div>
                <ul className="text-xl">
                  <li>Gather rsvps</li>
                  <li className="mt-4">Schedule invitations</li>
                  <li className="mt-4">Set reminders</li>
                  <li className="mt-4">Poll guests for dates</li>
                  <li className="mt-4">Save contact lists for recurring events</li>
                  <li className="mt-4">Customize +1s, guestlist visibility, and more</li>
                  <li className="mt-4">
                    <b>Guests don’t need to sign up</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mt-20">&nbsp;</div>
        </Container>
      </div>

      <div style={{ backgroundColor: newGray }} className="text-white">
        <Container>
          <div className="pt-20 text-5xl font-bold text-center">Event landing pages</div>

          <div className="flex flex-col items-center justify-center mt-20 lg:flex-row">
            <div className="max-w-2xl">
              <img src={origami} alt="Example event landing page" loading="lazy" />
            </div>

            <div className="max-w-2xl mt-16 lg:ml-16 lg:mt-0">
              <div>
                <ul className="text-xl">
                  <li>Upload an event image and fill in the time, place, and details.</li>
                  <li className="mt-4">
                    From one screen, schedule messages, embed gifs, and poll your guests for a date.
                  </li>
                  <li className="mt-4">Embed an event calendar on your website.</li>
                </ul>
              </div>

              <div className="mt-16">
                <div>
                  <Button
                    color="custom"
                    href={reverse('event_create')}
                    className="px-6 py-2 text-xl font-extrabold text-white uppercase rounded-md focus:outline-none md:text-2xl lg:text-3xl "
                    label="CUSTOMIZE"
                    style={{ backgroundColor: '#ef8f71' }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-20">&nbsp;</div>
        </Container>
      </div>

      <Testimonials />

      <div className="pt-40 text-center">
        <img src={hands} alt="Abstract line drawing of clasped hands" className="mx-auto" loading="lazy" />
        <div className="my-20 text-center">
          <Button
            color="custom"
            href={reverse('account_signup')}
            className="px-6 py-2 mx-auto text-xl font-extrabold text-white uppercase rounded-md focus:outline-none md:text-2xl lg:text-3xl"
            label="Sign up for free"
            style={{ backgroundColor: '#ef8f71' }}
          />
        </div>
      </div>
      <Footer />
    </RootLayout>
  );
};

export default LandingPage;
