import * as F from 'shared/shared/Functional';
import { EventWithExistQuery_event } from 'generated/EventWithExistQuery';
import { Poll, PollOption, formatPollOptions } from 'models/poll';
import { PollSelectionDialogDisplay } from 'components/pages/EventReadPage/polls/PollSelectionDialogDisplay';
import { UPDATE_EVENT_MUTATION } from 'utils/gql';
import { UpdateEvent, UpdateEventVariables } from 'generated/UpdateEvent';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { getDefaultTimezone } from 'utils/datetime';
import { useMutation } from '@apollo/client';

import React, { useState } from 'react';
import moment from 'moment';
import refetchEvent from 'api/refetchEvent';
import useEvent from 'utils/hooks/event/useEvent';

interface Props {
  poll: Poll;
  buttonClassName?: string;
}

export const PollSelectionDialog = (props: Props) => {
  const { poll, buttonClassName } = props;
  const options = formatPollOptions(poll.options);
  const { event } = useEvent();
  const [doUpdateEvent, { loading }] = useMutation<UpdateEvent, UpdateEventVariables>(UPDATE_EVENT_MUTATION);
  const [selectedOption, setSelectedOption] = useState<string>(options[0].id!);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  return (
    <PollSelectionDialogDisplay
      poll={poll}
      selectedOption={selectedOption}
      loading={loading}
      open={dialogIsOpen}
      buttonClassName={buttonClassName}
      setSelectedOption={setSelectedOption}
      setOpen={setDialogIsOpen}
      handleSave={() => {
        const newEvent = getNewEvent(selectedOption, poll, event!);
        doUpdateEvent({ variables: { ...newEvent } }).then(() => {
          refetchEvent(newEvent.id);
          addSnackbarMessage('Event updated!', 'success');
          setDialogIsOpen(false);
        });
      }}
    />
  );
};

function getNewEvent(optionId: string, poll: Poll, event: EventWithExistQuery_event) {
  const option: PollOption | undefined = poll.options.find((option) => optionId === option.id);
  if (!option) throw new Error('Poll option not found');

  const eventData = F.pick(
    event,
    'id',
    'title',
    'description',
    'location',
    'showGuestList',
    'showTicketAvailability',
    'guestsCanInvite',
    'disableComments',
    'captureGuestPhone',
    'privacy',
    'themePic',
    'maxPlusN',
    'ticketTypes',
    'currency'
  );

  const timezone = getDefaultTimezone();
  const startTime = moment.tz(option.text, timezone).toISOString();

  return {
    ...eventData,
    timezone,
    startTime,
  };
}
