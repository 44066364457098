import { Invite, iconStateMap, renderName, renderState } from 'models/invite';
import Icon from 'components/common/Icon/Icon';
import React from 'react';
interface Props {
  invite: Invite;
}

const MessageListEntry = (props: Props) => {
  const { invite } = props;
  const iconName = iconStateMap[invite.state!];

  return (
    <>
      <li key={invite.id} className="p-6 mb-4 border border-gray-400 border-solid rounded-lg">
        <div className="flex flex-col justify-between mb-2 md:mb-0 md:flex-row">
          <div className="text-lg">
            {' '}
            <Icon icon={iconName} className="mr-2" />
            {invite.rsvpAnswer ? invite.rsvpAnswer : renderState(invite.state!)}
          </div>
        </div>
        <div className="mt-2 text-sm text-gray-600">{messageInfo(invite)}</div>
        {renderAnswers(invite)}
      </li>
    </>
  );
};

export function messageInfo(invite: Invite): JSX.Element {
  const invitedBy = invite.sentBy?.name;
  const name = renderName(invite);
  const hasInvitedBy = invitedBy && invitedBy !== 'Someone';
  const hasOriginalEmail = name !== invite.originalEmail && invite.originalEmail;

  return (
    <>
      <span>{name}</span>
      {hasOriginalEmail && (
        <>
          <span className=""> • Invited as {invite.originalEmail}</span>
        </>
      )}
      {hasInvitedBy && (
        <>
          <span className=""> • Invited by {invitedBy} </span>
        </>
      )}
    </>
  );
}

const renderAnswers = (invite: Invite) => {
  if (!invite.answers || invite.answers.length === 0) {
    return null;
  }

  const filteredAnswers = invite.answers.filter((answer) => !!answer.answerText);

  const answerBlocks = filteredAnswers.map((answer, idx) => (
    <div key={idx} className="mt-2 text-sm">
      <span className="font-semibold">{answer.question?.questionText}</span>{' '}
      <span className="text-gray-600">{answer.answerText}</span>
    </div>
  ));

  return <div className="mt-4">{answerBlocks}</div>;
};

export default MessageListEntry;
