import { Controller, useForm } from 'react-hook-form';
import { EDIT_INVITE_MUTATION } from 'utils/gql';
import { EditInviteRSVP, EditInviteRSVPVariables } from 'generated/EditInviteRSVP';
import { Invite, getEmail, getName, renderName } from 'models/invite';
import { RsvpStates } from 'generated/globalTypes';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { useMutation } from '@apollo/client';

import { plusOnesOptionsForGuest } from 'utils/helpers';
import Dialog2, { actionStyles } from 'components/common/Dialog2/Dialog2';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import Input2 from 'components/common/Input2/Input2';
import React from 'react';
import RsvpStateWidget from 'components/widgets/RsvpStateWidget';
import SelectInput from 'components/common/SelectInput/SelectInput';
import useMutationFormHelpers from 'components/forms/useMutationFormHelpers';

interface Props {
  canPlusOne: boolean;
  invite: Invite;
  isEventAtCapacity: boolean;
  maxPlusN?: number;
  onClose: () => void;
  open: boolean;
}

type FormT = {
  originalName: string;
  rsvpPlusN: number;
  state: RsvpStates;
};

const getDefaultValues = (invite: Invite): FormT => {
  return {
    state: invite.state!,
    originalName: getName(invite),
    rsvpPlusN: invite.rsvpPlusN ?? 0,
  };
};

const EditInviteResponseModal: React.FC<Props> = ({
  canPlusOne,
  invite,
  isEventAtCapacity,
  maxPlusN,
  onClose,
  open,
}) => {
  const [executeMutation] = useMutation<EditInviteRSVP, EditInviteRSVPVariables>(EDIT_INVITE_MUTATION);
  const defaultValues = getDefaultValues(invite);

  const formContext = useForm<FormT>({ defaultValues });
  const formHelpers = useMutationFormHelpers<FormT, EditInviteRSVP, EditInviteRSVPVariables>({
    formContext,
    formToVariables: (formData) => ({
      ...formData,
      id: invite.id,
    }),
    mutation: executeMutation,
    resultKey: 'inviteEditRsvp',
    onSuccess: () => {
      addSnackbarMessage(`Edited ${renderName(invite)}'s invite`, 'success');
      onClose();
    },
  });
  const { Form, SubmitButton, FormLevelMessages, isSubmitting, FormStateContextProvider, onSubmit } = formHelpers;
  const canEditName = !invite.rsvpName;
  const showPlusN = canPlusOne;
  const plusNOptions = plusOnesOptionsForGuest(maxPlusN ?? 0);
  const { register, errors, control, watch } = formContext;

  return (
    <Dialog2 title="Invitation Details" isOpen={open} onClose={onClose}>
      <FormStateContextProvider onSubmit={onSubmit} isSubmitting={isSubmitting} formContext={formContext}>
        <Form>
          <FieldLayout
            label="Name"
            error={errors.originalName}
            helpText={canEditName ? '' : "This name was set by the guest, so it can't be edited."}
          >
            <Input2 name="originalName" disabled={isSubmitting} readOnly={!canEditName} ref={register} />
          </FieldLayout>
          <FieldLayout label="Email address" className="mt-6">
            <div className="text-gray-700">{getEmail(invite)}</div>
          </FieldLayout>
          <FieldLayout label="RSVP Status" className="mt-6">
            <Controller
              as={RsvpStateWidget}
              name="state"
              control={control}
              onChange={([e]) => e}
              disabled={isSubmitting}
              value={(undefined as unknown) as any}
              attrs={{
                isEventAtCapacity,
              }}
            />
          </FieldLayout>

          <FieldLayout
            className={showPlusN ? 'mt-6' : 'hidden'}
            label={'Bringing +n?'}
            error={errors.rsvpPlusN}
            alignLeft
          >
            <Controller
              as={SelectInput}
              name="rsvpPlusN"
              control={control}
              value={watch('rsvpPlusN')}
              onChange={([e]) => Number(e.target.value) ?? 0}
              attrs={{ options: plusNOptions, noFullWidth: false }}
              className="ml-2"
            />
          </FieldLayout>

          {invite.rsvpAnswer && (
            <FieldLayout label="RSVP Message" className="mt-4">
              <div className="text-gray-700">{invite.rsvpAnswer}</div>
            </FieldLayout>
          )}

          {renderAnswers(invite)}

          <FormLevelMessages className="mt-4" />
          <div className={actionStyles}>
            <SubmitButton label="Save" submittingLabel="Saving..." />
          </div>
        </Form>
      </FormStateContextProvider>
    </Dialog2>
  );
};

const renderAnswers = (invite: Invite) => {
  if (!invite.answers || invite.answers.length === 0) {
    return null;
  }

  const filteredAnswers = invite.answers.filter((answer) => !!answer.answerText);

  const answerBlocks = filteredAnswers.map((answer, idx) => (
    <FieldLayout key={idx} label={answer.question?.questionText} className="mt-2">
      <div className="text-gray-700">{answer.answerText}</div>
    </FieldLayout>
  ));

  return (
    <div className="mt-6">
      <div className="mb-2 text-lg leading-6 text-gray-900">Guest Responses</div>
      {answerBlocks}
    </div>
  );
};

export default EditInviteResponseModal;
