import * as m from 'models';
import { ApolloMutation } from 'utils/types';
import { Invite, MaybeRsvpResponse, RsvpResponse, getEmail, isRsvpResponse, toRsvpResponse } from 'models/invite';
import { InviteToken } from 'models/invite-token';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { getAnswerToQuestions } from 'models/answers';
import { isEventAtCapacity } from 'shared/shared/utils';

import BlockedRsvpForm from 'components/pages/EventReadPage/RsvpForm/BlockedRsvpForm';
import Cookie from 'js-cookie';
import LogoutOrReset from 'components/pages/EventReadPage/RsvpForm/LogoutOrReset';
import React from 'react';
import RsvpFormInner, { FormT as RsvpFormT } from 'components/pages/EventReadPage/RsvpForm/RsvpFormInner';
import RsvpStateWidget from 'components/widgets/RsvpStateWidget';
import UserChangeContext from 'components/App/UserChangeContext';
import classNames from 'classnames';
import styles from './RsvpForm.module.scss';

interface Props {
  defaultRsvp?: string;
  doMutation: ApolloMutation;
  event: m.Event;
  invite?: Invite;
  inviteToken?: InviteToken;
  sendMagicLink: (args: any) => void;
  user: m.User;
}

interface State {
  open: boolean;
  preventClose: boolean;
  selectedRsvp: MaybeRsvpResponse;
  selectedRsvpBeforeMutation?: MaybeRsvpResponse;
  state: 'editing' | 'submitting';
}

interface AnswerToQuestion {
  questionId: string;
  answerText: string;
}

const getRsvpToastMessagesForRsvpState = (isChangingResponse: boolean) => ({
  y: `🎉 You ${isChangingResponse ? 'changed your RSVP to' : 'marked yourself as'} going!`,
  n: `You ${isChangingResponse ? 'changed your RSVP to' : 'marked yourself as'} not going.`,
  m: `You ${isChangingResponse ? 'changed your RSVP to' : 'marked yourself as'} maybe.`,
});

const getSuccessMessage = (invite: Invite, selectedRsvpBeforeMutation: MaybeRsvpResponse): string => {
  const hadValidRsvp = !!(selectedRsvpBeforeMutation && isRsvpResponse(selectedRsvpBeforeMutation));
  const newRsvp = invite?.state?.toLowerCase() as RsvpResponse;
  const rsvpChanged = selectedRsvpBeforeMutation !== newRsvp;
  const rsvpToastMessages = getRsvpToastMessagesForRsvpState(hadValidRsvp && rsvpChanged);

  if (!hadValidRsvp) {
    return rsvpToastMessages[newRsvp];
  } else if (rsvpChanged) {
    return rsvpToastMessages[newRsvp];
  } else {
    return 'RSVP updated!';
  }
};

const shouldBlockRsvp = (inviteToken: InviteToken | undefined, eventId: string, user: m.User): boolean => {
  const disRsvpWithToken = inviteToken && inviteToken.didRsvp;
  const localInvite = Cookie.get(`event_${eventId}_invite`);
  return !!(!m.isAuthenticated(user) && !localInvite && disRsvpWithToken);
};

class DoRsvpForm extends React.Component<Props, State> {
  static contextType = UserChangeContext;
  context!: React.ContextType<typeof UserChangeContext>;

  constructor(props: Props) {
    super(props);
    const { invite } = props;
    const defaultRsvp = toRsvpResponse(props.defaultRsvp);
    const selectedRsvp = invite && invite.state && isRsvpResponse(invite.state) ? invite.state : defaultRsvp;
    this.state = {
      open: !!defaultRsvp,
      preventClose: false,
      selectedRsvp,
      state: 'editing',
    };
  }

  componentDidMount() {
    this.autoRsvp();
  }

  componentDidUpdate = (prevProps: Props, _: State) => {
    const { invite, defaultRsvp, inviteToken } = this.props;

    if (inviteToken && prevProps.inviteToken && prevProps.inviteToken.token !== inviteToken.token) {
      this.autoRsvp();
    }

    if (prevProps.defaultRsvp !== defaultRsvp) {
      const { invite } = this.props;
      const defaultRsvp = toRsvpResponse(this.props.defaultRsvp);

      this.setState({
        open: !!defaultRsvp,
        selectedRsvp: this.getSelectedRsvp(invite, defaultRsvp),
      });
    }

    if (prevProps.invite !== invite) {
      this.setState({
        selectedRsvp: this.getSelectedRsvp(invite, defaultRsvp),
      });
    }
  };

  private autoRsvp = () => {
    const { inviteToken, invite, user } = this.props;
    const { selectedRsvp } = this.state;
    const defaultRsvp = toRsvpResponse(this.props.defaultRsvp);
    const hasResponded = (invite && invite.state && isRsvpResponse(invite.state)) || inviteToken?.didRsvp;

    if (hasResponded || !defaultRsvp) {
      return;
    }

    // auto rsvp when clicked from email (for non-ticketed events)
    if (inviteToken && !inviteToken.didRsvp) {
      const formResult = m.isAuthenticated(user)
        ? { email: user.getPrimaryEmail(), name: user.name }
        : { email: inviteToken.userEmail, name: '' };
      const rsvpResponse = selectedRsvp && isRsvpResponse(selectedRsvp) ? selectedRsvp : undefined;
      this.handleSubmit({ ...formResult, rsvpAnswer: '', plusN: 0 }, rsvpResponse, true);
    } else if (invite && m.isAuthenticated(user)) {
      this.handleSubmit({ email: getEmail(invite), name: user.name, rsvpAnswer: '', plusN: 0 }, defaultRsvp, true);
    }
  };

  private inviteHasRsvpResponse(): boolean {
    const { invite } = this.props;
    return !!invite && isRsvpResponse(invite.state!);
  }

  private getSelectedRsvp(invite?: Invite, defaultRsvp?: string): MaybeRsvpResponse {
    return invite?.state && isRsvpResponse(invite.state) ? invite.state : (defaultRsvp as MaybeRsvpResponse);
  }

  private handleResponseButtonClick = (clickedRsvp: MaybeRsvpResponse) => {
    if (clickedRsvp == null) {
      throw new Error('Not expecting null response');
    }

    const clickedSame = clickedRsvp === this.state.selectedRsvp;
    const hasSavedValue = this.inviteHasRsvpResponse();

    let nextRsvpValue: MaybeRsvpResponse = clickedRsvp;
    let open = true;
    if (!hasSavedValue && clickedSame) {
      // Reset form if they didn't save their initial RSVP
      open = false;
      nextRsvpValue = undefined;
    } else if (clickedSame) {
      open = !this.state.open;
      // Reset form if they didn't save their changed RSVP
      nextRsvpValue = this.props.invite!.state! as MaybeRsvpResponse;
    }

    this.setState({
      selectedRsvp: nextRsvpValue,
      open: open,
    });
  };

  private handleSubmit = (formResult: RsvpFormT, rsvpResponse?: RsvpResponse, isAutoRsvp = false) => {
    const { name = '', email, rsvpAnswer, plusN } = formResult;
    const { selectedRsvp } = this.state;
    const { event, invite, doMutation } = this.props;

    const variables = {
      eventId: event.id,
      rsvpName: name,
      email: email,
      name: name,
      rsvpAnswer,
      inviteState: rsvpResponse || selectedRsvp,
      rsvpPlusN: plusN,
      eventAnswers: getAnswerToQuestions<RsvpFormT>(formResult),
    };

    this.setState({
      state: 'submitting',
      selectedRsvpBeforeMutation: this.inviteHasRsvpResponse() ? (invite!.state! as RsvpResponse) : undefined,
      preventClose: isAutoRsvp,
    });

    doMutation({ variables }).then((result) => {
      return this.onMutationDone(result, variables);
    });
  };

  private onMutationDone = (result: any, variables: any) => {
    this.setState({
      state: 'editing',
    });

    let invite;

    const { eventRsvpCreate, eventRsvpUpdate } = result.data;

    if (eventRsvpCreate) {
      const { ok, errors, signedCookie } = eventRsvpCreate;
      invite = eventRsvpCreate.invite;

      if (!ok && errors) {
        const error = errors[0];

        if (error.fieldName === 'email') {
          const { sendMagicLink, event } = this.props;
          sendMagicLink({
            variables: {
              eventId: event.id,
              email: variables.email,
            },
          });
          addSnackbarMessage(
            "This email has already been used to RSVP. We're emailing you a link to edit your RSVP.",
            'info'
          );
          return;
        } else {
          addSnackbarMessage(error.message, 'error');
        }

        return;
      }

      if (signedCookie) {
        Cookie.set(signedCookie.name, signedCookie.value);
      }
    } else if (eventRsvpUpdate) {
      const { ok, errors } = eventRsvpUpdate;
      invite = eventRsvpUpdate.invite;

      if (!ok && errors) {
        addSnackbarMessage(errors[0].message, 'error');
        return;
      }
    } else {
      addSnackbarMessage('Something went wrong processing your RSVP.', 'error');
      return;
    }

    const message = getSuccessMessage(invite, this.state.selectedRsvpBeforeMutation);
    addSnackbarMessage(message, 'success');

    if (this.state.preventClose) {
      this.setState({ preventClose: false });
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    const { event, invite, inviteToken, user } = this.props;
    const { selectedRsvp, open } = this.state;
    const rsvpResponseSaved = this.inviteHasRsvpResponse();
    const shouldBlockForm = shouldBlockRsvp(inviteToken, event.id, user);
    const hasRsvpEmailInForm = (open || shouldBlockForm) && invite?.rsvpEmail;

    return (
      <div className={styles.RsvpForm}>
        <div className={styles.RsvpFormHeader}>
          <div className={styles.RsvpFormHeaderHeading}>{rsvpResponseSaved ? 'Your RSVP' : 'Can you make it?'}</div>
        </div>
        <div className="mt-4">
          <div className={styles.RsvpFormStateWidgetWrapper}>
            <RsvpStateWidget
              onChange={this.handleResponseButtonClick}
              disabled={shouldBlockForm}
              value={selectedRsvp}
              attrs={{
                isEventAtCapacity: isEventAtCapacity(event.maxCapacity, event.confirmedGuests!),
              }}
            />
          </div>
          {shouldBlockForm && (
            <BlockedRsvpForm
              eventId={event.id}
              invite={invite}
              inviteToken={inviteToken}
              sendMagicLink={this.props.sendMagicLink}
            />
          )}
          {!shouldBlockForm && open && (
            <>
              {/* <hr> is used here because <Divider /> was causing too many issues with style and spacing */}
              <hr className={'thin-divider'} />
              <div className={styles.RsvpFormInnerWrapper}>
                <RsvpFormInner
                  invite={invite}
                  event={event}
                  selectedRsvp={selectedRsvp}
                  handleSubmit={this.handleSubmit}
                  pending={this.state.state === 'submitting'}
                  user={this.props.user}
                />
              </div>
            </>
          )}
          {hasRsvpEmailInForm && (
            <div className={classNames(styles.RsvpFormLogoutLinkWrapper, { [styles.blocked]: shouldBlockForm })}>
              Not you? <LogoutOrReset eventId={event.id}>RSVP as someone else</LogoutOrReset>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DoRsvpForm;
