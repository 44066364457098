import CloseIcon from '@material-ui/icons/Close';

import { Drawer, FormControlLabel, FormHelperText, IconButton, Switch } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';

interface Props {
  checked?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  fieldName?: string;
  hasValues?: boolean;
  helpText?: string;
  hidden?: boolean;
  id?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  readOnly?: boolean;
  styleName?: 'expanded';
  subtitle?: string;
  titleForButton: string;
  titleForDrawer: string;
}

const OptionExpandSwitch = (props: Props) => {
  const {
    checked,
    children,
    fieldName,
    hasValues,
    helpText,
    hidden,
    onChange,
    readOnly,
    styleName: style,
    subtitle,
    titleForButton,
    titleForDrawer,
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [switchEnabled, setSwitchEnabled] = useState<boolean>(!!hasValues);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchEnabled(event.target.checked);
  };

  return (
    <>
      <div className={hidden ? 'hidden' : 'flex items-center'}>
        <FormControlLabel
          control={
            <Switch
              className=""
              inputProps={{ id: props.id }}
              checked={checked}
              onChange={(event) => {
                handleSwitchChange(event);
                onChange?.(event, event.target.checked);
              }}
              color="primary"
              value={fieldName}
              readOnly={readOnly}
              disabled={props.disabled}
            />
          }
          label={''}
        />
        <div className="text-left">
          <div>
            {titleForButton}
            {/* Use blank help text to preserve vertical alignment */}
            {helpText && <FormHelperText className="mt-6">{helpText}</FormHelperText>}
          </div>
        </div>
      </div>
      {switchEnabled && (
        <span
          className="ml-12 font-medium text-orange-500 border-b border-orange-500 border-dotted cursor-pointer"
          style={{ width: 'fit-content' }}
          onClick={toggleDrawer(true)}
        >
          {hasValues ? 'Edit' : 'Add'} questions
        </span>
      )}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={onCloseDrawer}
        PaperProps={{ style: { backgroundColor: '#FDFBF8' } }}
      >
        <div className="max-w-screen-lg">
          <div className="pl-6 pr-2 md:pl-8 md:pr-4">
            <div className="bottom-0 flex items-center justify-between h-16">
              <div className="text-2xl">{titleForDrawer}</div>

              <IconButton aria-label="close" onClick={onCloseDrawer}>
                <CloseIcon className="text-gray-600" />
              </IconButton>
            </div>
            <div className="mb-6">{subtitle}</div>
            {children}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default OptionExpandSwitch;
