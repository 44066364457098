import { ACCESSORS, COLUMNS, Invite } from './config';
import { EVENT_INVITES_QUERY } from 'utils/gql';
import { EventInvites, EventInvitesVariables } from 'generated/EventInvites';
import { Loading } from 'components/common';
import { getCsvFilename } from 'utils/helpers';
import { objMapValues } from 'shared/shared/Functional';
import { useQuery } from '@apollo/client';
import DashboardTable from 'components/pages/Dashboard/DashboardTable/DashboardTable';
import React from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  eventId: string;
  onSetWide: (wide: boolean) => void;
  password?: string;
  wide: boolean;
}

const EventInvitesTable = ({ eventId, wide, onSetWide, password }: Props) => {
  const { data: invites, loading } = useQuery<EventInvites, EventInvitesVariables>(EVENT_INVITES_QUERY, {
    variables: { id: eventId, password },
    fetchPolicy: 'cache-and-network',
  });

  const { event, loading: loadingEvent } = useEventClass();

  if (loadingEvent || loading || !event || !invites?.event?.inviteSetWithoutOwner) {
    return <Loading />;
  }

  const data = invites?.event?.inviteSetWithoutOwner || [];
  const downloadData = data.map((invite) => objMapValues(ACCESSORS, (acc) => acc(invite)));

  return (
    <DashboardTable<Invite>
      data={data}
      columns={COLUMNS}
      loading={loading}
      wide={wide}
      onSetWide={onSetWide}
      downloadData={downloadData}
      downloadFilename={getCsvFilename(event)}
      type="invites"
    />
  );
};

export default EventInvitesTable;
