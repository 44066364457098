import { CurrentAnswers } from 'models/answers';

import { Currency, EventPrivacy } from 'shared/shared/types';
import { DiscountCodeQuery_discountCode } from 'generated/DiscountCodeQuery';
import {
  EventWithExistQuery_event_questions,
  EventWithExistQuery_event_ticketTypes,
} from 'generated/EventWithExistQuery';
import { Listing_listing_listingEvents_event_forCalendar } from 'generated/Listing';
import { MeQuery_me, MeQuery_me_auths, MeQuery_me_mySubscription } from 'generated/MeQuery';
import { reverse } from 'router';
import moment from 'moment';

//
// Shared
//

export type Unknown = 'UNKNOWN';
export const Unknown: Unknown = 'UNKNOWN';

abstract class Model {
  id?: string;
  createdAt?: moment.Moment;
  updatedAt?: moment.Moment;
}

///
/// User, Person, EmailAuth
///

export type Unauthenticated = 'UNAUTHENTICATED';
export const Unauthenticated: Unauthenticated = 'UNAUTHENTICATED';

export type DemoUser = 'DEMO_USER';
export const DemoUser: DemoUser = 'DEMO_USER';

class Person extends Model {
  constructor(
    public id: string,
    public name: string,
    public enableProEmails: boolean,
    public stripeAccountId: string | null,
    public stripeChargesEnabled: boolean | null,
    public stripeCustomerId: string | null,
    public zoomUserId: string | null,
    public listingId: string | null,
    public mySubscription: MeQuery_me_mySubscription,
    public auths?: MeQuery_me_auths,
    public features?: string[]
  ) {
    super();
  }

  getPrimaryEmail = (): string => {
    if (this.auths!.google.length) {
      return this.auths!.google[0].email;
    } else {
      return this.auths!.email[0].email;
    }
  };
}

export type User = Unknown | Unauthenticated | DemoUser | Person;

function isAuthenticated(user: User): user is Person {
  return user !== Unknown && user !== Unauthenticated && user !== DemoUser;
}

function isEmailConfirmed(auths: MeQuery_me_auths | undefined | null): boolean {
  return !!auths?.isVerified;
}

export const hasFeature = (user: User, feature: string): boolean => {
  if (!isAuthenticated(user)) {
    return false;
  }
  return (user.features || []).includes(feature);
};

export const usersEqual = (u1: User, u2: User) => {
  if (u1 === u2) {
    // Cases: both are Unknown, both are Unauthenticated, or identical Person objects
    return true;
  }
  if (!isAuthenticated(u1) || !isAuthenticated(u2)) {
    // Case: either is one Unknown/Unauthenticated, so the above equality test sufficed
    return false;
  }
  return u1.id === u2.id;
};

const parsePerson = (personData: MeQuery_me): Person => {
  return new Person(
    personData.id,
    personData.name,
    personData.enableProEmails,
    personData.stripeAccountId,
    personData.stripeChargesEnabled,
    personData.stripeCustomerId,
    personData.zoomUserId,
    personData.listingId,
    personData.mySubscription,
    personData.auths,
    personData.features
  );
};

///
/// Event
///

export const renderTime = (m: moment.Moment) => {
  if (!m.minute()) {
    return m.format('h a');
  } else {
    return m.format('h:mm a');
  }
};

export const renderDateTimeShort = (m: moment.Moment) => {
  return m.format('MMM. D, YYYY, ') + renderTime(m);
};

const renderShortDate = (m: moment.Moment) => {
  return m.format('MMMM Do YYYY');
};

export enum DemoType {
  Id = 'demo',
}

class Event extends Model {
  getUrl = () => {
    if (!this.id) {
      return '/event/create/demo';
    }

    return reverse('event_read', { id: this.id });
  };

  renderStartDateShort = () => {
    if (!this.startTime) {
      return 'TBD';
    }
    return renderShortDate(this.startTime);
  };

  renderStartTimeShort = () => {
    if (!this.startTime) {
      return 'TBD';
    }
    return renderDateTimeShort(this.startTime);
  };

  renderStartTime = () => {
    if (!this.startTime) {
      return 'TBD';
    }
    return renderTime(this.startTime);
  };

  effectiveEndTime = (): moment.Moment | null => {
    if (!this.startTime) {
      return null;
    }
    return this.endTime || this.startTime.clone().add({ hours: 1 });
  };

  constructor(
    public id: string,
    public title: string,
    public description: string,
    public location: string,
    public startTime: moment.Moment | null,
    public timezone: string,
    public endTime: moment.Moment | undefined,
    public showGuestList: boolean,
    public showTicketAvailability: boolean,
    public guestsCanInvite: boolean,
    public captureGuestPhone: boolean,
    public privacy: EventPrivacy,
    public hostedByOverride: string,
    public disableComments: boolean,
    public currency: Currency,
    public owner?: Person,
    // public hosts?: string[],
    public themePic?: string,
    public maxCapacity?: number | null,
    public confirmedGuests?: number,
    public invitedGuests?: number,
    public maxPlusN?: number,
    public thread?: any,
    public poll?: any,
    public questions?: Question[] | null,
    public ticketTypes?: TicketTypeForPurchase[] | null,
    public eventsFromRun?: any[] | null,
    public discountCodes?: DiscountCode[] | null,
    public hostedByText?: string,
    public updatedAt?: moment.Moment | undefined,
    public createdAt?: moment.Moment | undefined,
    public checkoutButtonLabel?: string,
    public isPast?: boolean,
    public isRunTemplate?: boolean,
    public appendAccessTokenToUrl?: boolean,
    public proxyVirtualLocation?: boolean,
    public locationReleaseTimeDelta?: moment.Duration | undefined,
    public numTickets?: number | null,
    public confirmedGuestMessage?: string,
    public facebookPixelId?: string,
    public showDiscountCodeInput?: boolean,
    public bgColor?: string,
    public bgNoBackdrop?: boolean,
    public forCalendar?: Listing_listing_listingEvents_event_forCalendar | null,
    public useMixilyVirtualVenue?: boolean,
    public virtualVenueContent?: string | undefined,
    public registrationEndTime?: moment.Moment | undefined,
    public password?: string | undefined,
    public currentAnswers?: CurrentAnswers[] | null
  ) {
    super();
  }
}

// todo: don't return fake data. decide how to sensibly construct models with optional params.
// Note: startTime and endTime arrive from the server localized to UTC. On the JS model,
// we localize to the event's timezone for ease of use.
const parseEvent = (eventData: any): Event => {
  const owner = eventData.owner ? parsePerson(eventData.owner) : undefined;
  const startTime = eventData.startTime ? moment(eventData.startTime).tz(eventData.timezone) : null;
  const endTime = eventData.endTime ? moment(eventData.endTime).tz(eventData.timezone) : undefined;
  const updatedAt = eventData.updatedAt ? moment(eventData.updatedAt) : undefined;
  const createdAt = eventData.createdAt ? moment(eventData.createdAt) : undefined;
  const locationReleaseTimeDelta = eventData.locationReleaseTimeDelta
    ? moment.duration(eventData.locationReleaseTimeDelta)
    : undefined;
  const registrationEndTime = eventData.registrationEndTime ? moment(eventData.registrationEndTime) : undefined;
  return new Event(
    eventData.id,
    eventData.title,
    eventData.description,
    eventData.location,
    startTime,
    eventData.timezone,
    endTime,
    eventData.showGuestList,
    eventData.showTicketAvailability,
    eventData.guestsCanInvite,
    eventData.captureGuestPhone,
    eventData.privacy,
    eventData.hostedByOverride,
    eventData.disableComments,
    eventData.currency,
    owner,
    // eventData.hosts,
    eventData.themePic,
    eventData.maxCapacity,
    eventData.confirmedGuests,
    eventData.invitedGuests,
    eventData.maxPlusN,
    eventData.thread,
    eventData.poll,
    eventData.questions,
    eventData.ticketTypes,
    eventData.eventsFromRun,
    eventData.discountCodes,
    eventData.hostedByText,
    updatedAt,
    createdAt,
    eventData.checkoutButtonLabel,
    eventData.isPast,
    eventData.isRunTemplate,
    eventData.appendAccessTokenToUrl,
    eventData.proxyVirtualLocation,
    locationReleaseTimeDelta,
    eventData.numTickets,
    eventData.confirmedGuestMessage,
    eventData.facebookPixelId,
    eventData.showDiscountCodeInput,
    eventData.bgColor,
    eventData.bgNoBackdrop,
    eventData.forCalendar,
    eventData.useMixilyVirtualVenue,
    eventData.virtualVenueContent,
    registrationEndTime,
    eventData.password,
    eventData.currentAnswers
  );
};

export type TicketTypeForPurchase = EventWithExistQuery_event_ticketTypes;
export type DiscountCode = DiscountCodeQuery_discountCode;
export type Question = EventWithExistQuery_event_questions;

///
/// Zoom
///

export interface IZoomMeetingOptions {
  topic: string;
  type: number;
  start_time: string | undefined;
  timezone: string | undefined;
  duration: number | undefined;
  settings: {
    host_video: boolean;
    participant_video: boolean;
    join_before_host: boolean;
  };
}

export { isAuthenticated, isEmailConfirmed, Person, parsePerson, Event, parseEvent };
