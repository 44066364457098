import * as F from 'shared/shared/Functional';
import { CreateEventVariables } from 'generated/CreateEvent';
import { EventWithExistQuery_event } from 'generated/EventWithExistQuery';
import { NoExtraProperties } from 'shared/shared/utils';
import { TicketTypeInput } from 'generated/globalTypes';

const pollDataToInput = (pollData: EventWithExistQuery_event['poll']): CreateEventVariables['poll'] => {
  if (!pollData) {
    return null;
  }
  return pollData.options.map((pollOption) => pollOption.text);
};

const ticketDataToInput = (
  ticketData: EventWithExistQuery_event['ticketTypes']
): null | NoExtraProperties<TicketTypeInput>[] => {
  if (!ticketData) {
    return null;
  }
  return ticketData.map((tt): NoExtraProperties<TicketTypeInput> => F.omit(tt, 'id', '__typename'));
};

const questionsDataToInput = (questionsData: EventWithExistQuery_event['questions']) => {
  if (!questionsData) {
    return null;
  }
  return questionsData.map((q) => F.omit(q, 'id', '__typename'));
};

const discountDataToInput = (
  discountData: EventWithExistQuery_event['discountCodes']
): CreateEventVariables['discountCodes'] => {
  if (!discountData) {
    return null;
  }
  return discountData.map((dd) => F.pick(dd, 'type', 'code', 'amount', 'isActive'));
};

export const eventToMutationInput = (eventData: EventWithExistQuery_event): CreateEventVariables => {
  const copiedParms = F.omit(eventData, 'id', 'owner', 'guestPermissions', 'myPermissions', 'startTime', 'endTime');

  const params: NoExtraProperties<CreateEventVariables> = {
    ...copiedParms,
    poll: pollDataToInput(eventData.poll),
    ticketTypes: ticketDataToInput(eventData.ticketTypes),
    discountCodes: discountDataToInput(eventData.discountCodes),
    questions: questionsDataToInput(eventData.questions),
  };
  return params;
};
