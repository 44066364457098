import { TextWidget, WidgetProps } from 'components/widgets';

import { CurrentAnswers } from 'models/answers';
import { DateTime } from 'components/widgets/time-types';
import { EventFormContext, Options } from 'components/EventCreateUpdateForm/EventCreateUpdateForm/types';
import { QuestionFields } from 'components/EventCreateUpdateForm/QuestionsWidget/Questions';
import { getDefaultTimezone } from 'utils/datetime';
import { plusOnesOptionsForHost } from 'utils/helpers';

import DateTimeWidget from 'components/widgets/DateTimeWidget';
import IntegerWidget from 'components/widgets/NumberWidget/IntegerWidget';
import OptionExpandSwitch from 'components/OptionSwitch/OptionExpandSwitch';
import OptionSwitch from 'components/OptionSwitch/OptionSwitch';
import React, { ChangeEvent, useEffect, useState } from 'react';
import SelectInput from 'components/common/SelectInput/SelectInput';
import moment from 'moment';

enum FieldName {
  ALLOW_PLUS_ONES = 'allowPlusOnes',
  CAPTURE_GUEST_PHONE = 'captureGuestPhone',
  GUESTS_CAN_INVITE = 'guestsCanInvite',
  MAX_CAPACITY = 'maxCapacity',
  PASSWORD_PROTECTED = 'passwordProtected',
  SHOW_GUEST_LIST = 'showGuestList',
  SHOW_TICKET_AVAILABILITY = 'showTicketAvailability',
  QUESTIONS = 'questions',
}

interface Attrs {
  currentAnswers?: CurrentAnswers[] | null;
  endTime: DateTime | undefined;
  hasMaxCapacity: boolean;
  hasQuestions: boolean;
  hasRegistrationEndTime: boolean;
  hideCapturePhone: boolean;
  hideGuestList: boolean;
  hidePasswordProtected: boolean;
  hidePlusOnes: boolean;
  hideTicketAvailability: boolean;
  isFree: boolean;
  mxForm: EventFormContext;
  startTime: DateTime | undefined;
  timezone: string;
  setValue: Function;
}

const getDefaultRegistrationEnd = (
  registrationEndTime: moment.Moment | undefined,
  startTime: moment.Moment | DateTime | undefined | null,
  endTime: moment.Moment | DateTime | undefined,
  tz: string | undefined
) => {
  if (registrationEndTime) {
    return registrationEndTime.toObject();
  }
  const timezone = tz ?? getDefaultTimezone();
  const init = endTime
    ? moment.tz(endTime, timezone).subtract(1, 'week')
    : moment.tz(startTime ?? undefined, timezone).subtract(30, 'minute');
  return init.toObject();
};

const OptionsWidget: React.FC<WidgetProps<Options, Attrs>> = (props) => {
  const { onChange, value, attrs } = props;

  const {
    captureGuestPhone,
    guestsCanInvite,
    maxCapacity,
    maxPlusN,
    password,
    registrationEndTime,
    showGuestList,
    showTicketAvailability,
  } = value;
  const {
    currentAnswers,
    endTime,
    hasMaxCapacity,
    hasQuestions,
    hasRegistrationEndTime,
    hideCapturePhone,
    hideGuestList,
    hidePasswordProtected,
    hidePlusOnes,
    hideTicketAvailability,
    isFree,
    mxForm,
    setValue,
    startTime,
    timezone,
  } = attrs!;
  const [isRegistrationEndDateScheduled, setIsRegistrationEndDateScheduled] = useState<boolean>(false);
  const [allowPlusNEdit, setAllowPlusNEdit] = useState<boolean>(false);
  const [allowPasswordProtectedEdit, setPasswordProtectedEdit] = useState<boolean>(false);
  const [allowMaxCapacityEdit, setMaxCapacityEdit] = useState<boolean>(false);
  const [allowQuestionsEdit, setQuestionsEdit] = useState<boolean>(hasQuestions);

  const registration =
    registrationEndTime ?? getDefaultRegistrationEnd(registrationEndTime, startTime, endTime, timezone);

  const isMaxPlusNEditable = maxPlusN === 0 && allowPlusNEdit;
  const plusNValue = isMaxPlusNEditable ? 1 : maxPlusN;

  const hasAnswers = !!(currentAnswers && currentAnswers?.length > 0);
  const getQuestionsHelpText = (isFree: boolean, hasAnswers: boolean) => {
    const baseText = isFree
      ? 'Ask guests to answer custom questions before completing their RSVP'
      : 'Require guests to answer key questions before completing their order';

    const deleteWarning = hasAnswers ? '. Questions cannot be deleted once they have been answered by any guest' : '';

    return `${baseText}${deleteWarning}`;
  };

  const helpTextForQuestions = getQuestionsHelpText(isFree, hasAnswers);

  useEffect(() => {
    if (registrationEndTime && !isRegistrationEndDateScheduled) {
      setIsRegistrationEndDateScheduled(true);
    }
    if (maxPlusN > 0) {
      setAllowPlusNEdit(true);
    }
    if (maxCapacity != null && maxCapacity > 0) {
      setMaxCapacityEdit(true);
    }
    if (password) {
      setPasswordProtectedEdit(true);
    }
  }, [isRegistrationEndDateScheduled, registrationEndTime, maxPlusN, password, maxCapacity]);

  function handleSwitchChange(fieldName: FieldName) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        [fieldName]: e.target.checked,
      });
    };
  }

  function toggleRegistrationEndTime() {
    const newShowEndTime = !isRegistrationEndDateScheduled;
    setIsRegistrationEndDateScheduled(newShowEndTime);

    if (newShowEndTime && !registrationEndTime) {
      onChange({
        ...value,
        registrationEndTime: getDefaultRegistrationEnd(registrationEndTime, startTime, endTime, timezone),
      });
    } else if (!newShowEndTime && registrationEndTime) {
      onChange({
        ...value,
        registrationEndTime: undefined,
      });
    }
  }

  function toggleMaxCapacity() {
    const newMaxCapacity = !allowMaxCapacityEdit;

    if (!newMaxCapacity) {
      setValue('options', { ...value, maxCapacity: null });
    }
    setMaxCapacityEdit(newMaxCapacity);
  }

  function togglePlusN() {
    const newPlusN = !allowPlusNEdit;
    if (!newPlusN) {
      setValue('options', { ...value, maxPlusN: 0 });
    } else {
      setValue('options', { ...value, maxPlusN: 1 });
    }
    setAllowPlusNEdit(newPlusN);
  }

  function togglePasswordProtected() {
    const newPasswordProtected = !allowPasswordProtectedEdit;
    if (!newPasswordProtected) {
      setValue('options', { ...value, password: '' });
    }
    setPasswordProtectedEdit(newPasswordProtected);
  }

  function toggleEditQuestions() {
    const newQuestionsEdit = !allowQuestionsEdit;
    if (!newQuestionsEdit) {
      setValue('options', { ...value, questions: [] });
    }
    setQuestionsEdit(newQuestionsEdit);
  }

  useState<boolean>(false);

  return (
    <div className="input-container vstack-small">
      <OptionSwitch
        checked={guestsCanInvite}
        onChange={handleSwitchChange(FieldName.GUESTS_CAN_INVITE)}
        fieldName={FieldName.GUESTS_CAN_INVITE}
        title="Allow Guests to Invite"
        helpText="Guests can invite others by submitting email addresses via the event page"
      />
      <OptionSwitch
        hidden={hideGuestList}
        checked={showGuestList}
        onChange={handleSwitchChange(FieldName.SHOW_GUEST_LIST)}
        fieldName={FieldName.GUESTS_CAN_INVITE}
        title="Show Guest List"
        helpText="The guest list is visible on the event page"
      />
      <OptionSwitch
        hidden={hidePlusOnes}
        checked={allowPlusNEdit}
        onChange={togglePlusN}
        fieldName={FieldName.ALLOW_PLUS_ONES}
        title="Allow +1s"
        helpText="Show a +1 (or more) option on the RSVP form"
      />
      {allowPlusNEdit && !hidePlusOnes && (
        <div className="mt-2 ml-12">
          <div className="vstack-small">
            <SelectInput
              value={plusNValue}
              disabled={false}
              onChange={(v) => onChange({ ...value, maxPlusN: Number(v.target.value) ?? 0 })}
              attrs={{ options: plusOnesOptionsForHost(10), noFullWidth: false }}
            />
          </div>
        </div>
      )}
      <OptionSwitch
        hidden={!hasMaxCapacity}
        checked={allowMaxCapacityEdit}
        onChange={toggleMaxCapacity}
        fieldName={FieldName.MAX_CAPACITY}
        title="Max capacity"
        helpText="Limit capacity on your event"
      />
      {allowMaxCapacityEdit && hasMaxCapacity && (
        <div className="mt-2 ml-12">
          <div className="vstack-small">
            <IntegerWidget
              value={maxCapacity}
              disabled={false}
              onChange={(quantity) => {
                onChange({
                  ...value,
                  maxCapacity: isNaN(quantity as any) ? null : Number(quantity),
                });
              }}
              attrs={{
                placeholder: 'Unlimited',
                isEmptyAllowed: true,
              }}
            />
          </div>
        </div>
      )}
      <OptionSwitch
        hidden={hidePasswordProtected}
        checked={allowPasswordProtectedEdit}
        onChange={togglePasswordProtected}
        fieldName={FieldName.PASSWORD_PROTECTED}
        title="Password Protected"
        helpText="Set up a password for attendees to access the event"
      />
      {allowPasswordProtectedEdit && !hidePasswordProtected && (
        <div className="mt-2 ml-12">
          <div className="vstack-small">
            <TextWidget
              value={password}
              disabled={false}
              attrs={{ size: 'medium', placeholder: 'e.g. MyPassword' }}
              onChange={(password) => onChange({ ...value, password })}
            />
          </div>
        </div>
      )}
      <OptionSwitch
        hidden={hideCapturePhone}
        checked={captureGuestPhone}
        onChange={handleSwitchChange(FieldName.CAPTURE_GUEST_PHONE)}
        fieldName={FieldName.CAPTURE_GUEST_PHONE}
        title="Ask for Phone Number"
        helpText="Guests will be required to enter their phone number during checkout"
      />
      {hasRegistrationEndTime && (
        <>
          <OptionSwitch
            checked={isRegistrationEndDateScheduled}
            onChange={toggleRegistrationEndTime}
            fieldName="switchRegistrationEndTime"
            title="Registration End Time"
            helpText="Set up a password a registration end time"
          />
          {isRegistrationEndDateScheduled && (
            <div className="mt-3 ml-12">
              <div className="vstack-small">
                <DateTimeWidget
                  value={registration}
                  disabled={false}
                  onChange={(v) => onChange({ ...value, registrationEndTime: v })}
                  attrs={{
                    timePill: {
                      content: moment.tz(timezone!).format('z'),
                    },
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}

      <OptionSwitch
        hidden={hideTicketAvailability}
        checked={showTicketAvailability}
        onChange={handleSwitchChange(FieldName.SHOW_TICKET_AVAILABILITY)}
        fieldName={FieldName.SHOW_TICKET_AVAILABILITY}
        title="Display Ticket Availability"
        helpText="Let attendees know how many tickets are left to plan ahead"
      />

      <OptionExpandSwitch
        disabled={hasAnswers}
        hasValues={hasQuestions}
        checked={allowQuestionsEdit}
        onChange={toggleEditQuestions}
        fieldName={FieldName.QUESTIONS}
        titleForButton="Questions for Guests"
        titleForDrawer="Questions for Guests"
        subtitle={
          isFree
            ? 'Ask guests to answer custom questions before completing their RSVP'
            : 'Require guests to answer key questions before completing their order'
        }
        helpText={helpTextForQuestions}
      >
        <QuestionFields mxForm={mxForm} currentAnswers={currentAnswers} />
      </OptionExpandSwitch>
    </div>
  );
};

export default OptionsWidget;
