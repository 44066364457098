import { ReadOrder_order } from 'generated/ReadOrder';
import { analyticsAPI as analytics } from 'utils/api';
import { isIframed } from 'utils/embedded';
import { localStore } from 'utils/localstore';
import { prettyDate } from 'utils/datetime';
import { renderDiscountCode, renderDollars } from 'shared/shared/utils';
import { reverse } from 'router';
import AddToCalendar, { ButtonCalendarType } from 'components/pages/EventReadPage/AddToCalendar/AddToCalendar';
import Button from 'components/common/Button/Button';
import LineItems from 'components/pages/OrderReadPage/LineItems';
import OrderDetail from 'components/pages/OrderReadPage/OrderDetail';
import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import When from 'components/When/When';
import moment from 'moment';
import styles from './OrderReadPage.module.scss';

interface Props {
  order: ReadOrder_order;
}

const OrderComplete: React.FC<Props> = ({ order }) => {
  const [fbPixelEventSent, setFbPixelEventSent] = useState<boolean>(false);
  const event = order.tickets[0].ticketType.event;
  const eventFragment = Object.assign({}, event, {
    startTime: event.startTime ? moment(event.startTime) : null,
    endTime: event.endTime ? moment(event.endTime) : undefined,
  });
  const eventWhenFragment = { ...eventFragment, eventId: event.id, forCalendar: event.forCalendar };

  localStore.anonBoughtTickets.set(event.id, order.id);

  useEffect(() => {
    if (event.facebookPixelId && !fbPixelEventSent) {
      ReactPixel.init(event.facebookPixelId);
      ReactPixel.pageView();
      ReactPixel.track('Purchase', { currency: 'USD', value: order.total / 100 });
      setFbPixelEventSent(true);
    }
  }, [event.facebookPixelId, fbPixelEventSent, order.total]);

  useEffect(() => {
    analytics.track('OrderComplete');
  }, []);

  const eventUrl = event.partOfRunId
    ? reverse('run_read', { id: event.partOfRunId })
    : reverse('event_read', { id: event.id });

  // TODO: Dynamic dates based on venue information
  const thirdCoastPercussion = '5603424503236552293';
  const isThirdCoastPercussion = event.id === thirdCoastPercussion;
  const isVirtual = event.appendAccessTokenToUrl || event.proxyVirtualLocation;

  let locationUrl = event.location;

  if (isThirdCoastPercussion) {
    locationUrl = `https://thirdcoastpercussion.com/live`;
  } else if (event.proxyVirtualLocation) {
    locationUrl = reverse('event_proxy', { id: event.id });
  }

  return (
    <div>
      <div className={styles.Receipt}>
        <div className={styles.Title}>
          <div className="text-3xl">Order complete</div>
        </div>
        <div className={styles.DetailList}>
          <OrderDetail title="Email sent to" value={order.email} />
          <OrderDetail title="Order number" value={order.id} />
          <OrderDetail title="Order date" value={prettyDate(order.updatedAt)} />
          {order.discounts && order.discounts[0] && (
            <OrderDetail title="Discount Code" value={renderDiscountCode(order.discounts[0].discountCode)} />
          )}
          <OrderDetail title="Summary" value={<LineItems items={order.lineItems} />} />
          <OrderDetail title="Total" value={renderDollars(order.total, event.currency)} />
          <OrderDetail title="Paid" value={renderDollars(order.total, event.currency)} />
          <OrderDetail title="Balance" value={renderDollars(0, event.currency)} />
        </div>
      </div>
      {!!event.confirmedGuestMessage && (
        <div className="mt-12">
          <div className="text-3xl">Message from the host</div>
          <div className={styles.HostMessage}>
            <div dangerouslySetInnerHTML={{ __html: event.confirmedGuestMessage }} />
          </div>
        </div>
      )}
      {renderAnswers(order)}

      {isVirtual ? <Button label="Attend" href={locationUrl} className={'mt-8 px-10'} /> : null}
      {event.startTime && (
        <div className="mt-12">
          <div className="text-2xl">Mark your calendar</div>
          <div className="mt-2">
            <div className="flex flex-col xs:flex-row xs:items-center">
              <When event={eventWhenFragment} alignTop />
              <div className="inline-flex text-base align-middle sm:text-lg">
                <span className="hidden mx-1 xs:inline">&middot;</span>
                <span className="ml-8 xs:ml-0">
                  <AddToCalendar
                    eventId={eventWhenFragment.id}
                    forCalendar={eventWhenFragment.forCalendar}
                    type={ButtonCalendarType.LINK}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isIframed && (
        <div className={styles.Return}>
          Return to <a href={eventUrl}>{event.title}</a>
        </div>
      )}
    </div>
  );
};

const renderAnswers = (order: ReadOrder_order) => {
  if (!order.answers || order.answers.length === 0) {
    return null;
  }

  const filteredAnswers = order.answers.filter((answer) => !!answer.answerText);

  return (
    <div className="mt-12">
      <div className="text-3xl">Guest Responses</div>
      {filteredAnswers.map((answer) => (
        <div key={answer.id} className="mt-2">
          <div className="text-lg">{answer.question?.questionText}</div>
          <div className="text-gray-700">{answer.answerText}</div>
        </div>
      ))}
    </div>
  );
};

export default OrderComplete;
