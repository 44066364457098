import React from 'react';

import * as m from 'models';
import AdvancedOptionsWidget from 'components/EventCreateUpdateForm/AdvancedOptionsWidget/AdvancedOptionsWidget';
import CurrencyWidget from 'components/EventCreateUpdateForm/CurrencyWidget/CurrencyWidget';
import EventTypeWidget, { EventType } from 'components/EventCreateUpdateForm/EventTypeWidget/EventTypeWidget';
import FieldLayout from 'components/common/FieldLayout/FieldLayout';
import OptionsWidget from 'components/EventCreateUpdateForm/OptionsWidget/OptionsWidget';
import QuillRichTextWidget from 'components/widgets/QuillRichTextWidget/QuillRichTextWidget';
import ThemePicWidget from 'components/EventCreateUpdateForm/ThemePicWidget/ThemePicWidget';
import TicketFields from 'components/EventCreateUpdateForm/EventCreateUpdateForm/fields/Tickets/Tickets';
import TitleWidget from 'components/EventCreateUpdateForm/TitleWidget/TitleWidget';
import WhenWidget from 'components/EventCreateUpdateForm/WhenWidget/WhenWidget';
import WhereWidget from 'components/EventCreateUpdateForm/WhereWidget/WhereWidget';
import classNames from 'classnames';
import styles from './EventCreateUpdateFields.module.scss';
import useHasFeatureFlags from 'utils/hooks/user/useHasFeatureFlags';
import useUser from 'utils/hooks/user/useUser';

import { DEFAULT_IMAGE_URL, LABEL_CLASS } from './constants';
import { EventFormContext } from './types';
import { FEATURE_FLAGS, FIELDS } from 'shared/shared/json';
import { getDefaultTimezone } from 'utils/datetime';

interface Props {
  mxForm: EventFormContext;
  event?: m.Event;
}

const Label = (props: { label: string }) => <div className={LABEL_CLASS}>{props.label}</div>;

const EventCreateUpdateFields = ({ mxForm, event }: Props) => {
  const { onChange, formMethods, isSubmitting } = mxForm;
  const { watch, setValue } = formMethods;
  const { user } = useUser();

  const {
    canDisableComments,
    canSetFacebookPixelId,
    hasMultiCurrency,
    hasPasswordProtected,
    hasRegistrationEndTime,
    hostedByOverride,
  } = useHasFeatureFlags(
    FEATURE_FLAGS.canDisableComments,
    FEATURE_FLAGS.canSetFacebookPixelId,
    FEATURE_FLAGS.hasMultiCurrency,
    FEATURE_FLAGS.hasPasswordProtected,
    FEATURE_FLAGS.hasRegistrationEndTime,
    FEATURE_FLAGS.hostedByOverride
  );

  // Hide advanced options in demo, or if user doesn't have any available
  const hasAdvancedOptions =
    m.isAuthenticated(user) && (hostedByOverride || canDisableComments || canSetFacebookPixelId);

  const isFree = watch('eventType') === EventType.FREE;

  return (
    <>
      <FieldLayout error={formMethods.errors.themePic}>
        <ThemePicWidget
          onChange={(val) => onChange('themePic', val)}
          value={watch('themePic')}
          disabled={isSubmitting}
          attrs={{
            title: watch('title') || '',
            defaultImageUrl: event?.id ? '' : DEFAULT_IMAGE_URL,
          }}
        />
      </FieldLayout>

      <FieldLayout error={formMethods.errors.title} className="mt-6">
        <TitleWidget
          onChange={(val) => onChange('title', val)}
          value={watch('title')}
          disabled={isSubmitting}
          attrs={{
            autoFocus: !watch('title'),
            placeholder: 'Add Event Title',
            maxLength: FIELDS.title.maxLength,
            spellCheck: false,
          }}
        />
      </FieldLayout>

      <EventTypeWidget
        className="mt-2"
        onChange={(val) => onChange('eventType', val)}
        value={watch('eventType')}
        disabled={isSubmitting}
      />

      <div className="mt-6">
        {/* <label> behavior doesn't work with WhenWidget - selects first radio */}
        <Label label="When" />
        <FieldLayout error={formMethods.errors.when}>
          <WhenWidget
            onChange={(val) => {
              onChange('when', val);
              onChange('shouldSendConfirmation', true);
            }}
            value={watch('when')}
            disabled={isSubmitting}
            attrs={{ defaultTimezone: getDefaultTimezone() }}
          />
        </FieldLayout>
      </div>

      <div className="mt-6">
        {/* <label> behavior doesn't work with WhereWidget - selects first radio */}
        <Label label="Where" />
        <FieldLayout error={formMethods.errors.where as any}>
          <WhereWidget
            onChange={(val) => {
              onChange('where', val);
              onChange('shouldSendConfirmation', true);
            }}
            value={watch('where')}
            disabled={isSubmitting}
            attrs={{
              when: watch('when'),
            }}
          />
        </FieldLayout>
      </div>

      <FieldLayout label={<Label label="Details" />} error={formMethods.errors.description} className="mt-6">
        <QuillRichTextWidget
          onChange={(val) => onChange('description', val)}
          value={watch('description')}
          disabled={isSubmitting}
          attrs={{
            placeholder: 'Add a description of your event',
            className: styles.descriptionQuill,
          }}
        />
      </FieldLayout>

      {watch('eventType') === EventType.TICKETED && (
        <div className={hasMultiCurrency ? 'mt-6' : 'hidden'}>
          {/* <label> behavior doesn't make sense with OptionsWidget */}
          <Label label="Currency" />
          <FieldLayout error={formMethods.errors.currency}>
            <CurrencyWidget
              onChange={(val) => onChange('currency', val)}
              value={watch('currency')}
              disabled={isSubmitting}
              attrs={{
                event,
              }}
            />
          </FieldLayout>
        </div>
      )}

      <TicketFields
        currency={watch('currency')}
        mxForm={mxForm}
        event={event}
        className={classNames('mt-6', { hidden: watch('eventType') === EventType.FREE })}
      />

      <div className={'mt-6'}>
        {/* <label> behavior doesn't make sense with OptionsWidget */}
        <Label label="Options" />
        <FieldLayout error={formMethods.errors.options}>
          <OptionsWidget
            onChange={(val) => onChange('options', val)}
            value={watch('options')}
            disabled={isSubmitting}
            attrs={{
              currentAnswers: event?.currentAnswers,
              endTime: watch('when').end,
              hasMaxCapacity: isFree,
              hasRegistrationEndTime: !!hasRegistrationEndTime && isFree,
              hasQuestions:
                watch('options')?.questions?.length === 1 ? watch('options')?.questions?.[0].question !== '' : true,
              hideCapturePhone: isFree,
              hideGuestList: watch('eventType') === EventType.TICKETED,
              hidePasswordProtected: !hasPasswordProtected,
              hidePlusOnes: watch('eventType') === EventType.TICKETED,
              hideTicketAvailability: watch('eventType') === EventType.FREE,
              isFree: isFree,
              mxForm,
              setValue,
              startTime: watch('when').start,
              timezone: watch('when').timezone!,
            }}
          />
        </FieldLayout>
      </div>

      <div className={hasAdvancedOptions ? 'mt-6' : 'hidden'}>
        {/* <label> behavior doesn't make sense with AdvancedOptionsWidget */}
        <Label label="Advanced Options" />
        <FieldLayout error={formMethods.errors.advancedOptions}>
          <AdvancedOptionsWidget
            onChange={(val) => onChange('advancedOptions', val)}
            value={watch('advancedOptions')}
            disabled={isSubmitting}
            attrs={{
              hideGuestList: watch('eventType') === EventType.TICKETED,
              hidePlusOnes: watch('eventType') === EventType.TICKETED,
              hideCapturePhone: isFree,
            }}
          />
        </FieldLayout>
      </div>
    </>
  );
};

export default EventCreateUpdateFields;
