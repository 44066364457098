import ActionButton from 'components/common/ActionButton/ActionButton';
import DeleteIcon from '@material-ui/icons/Close';
import DownIcon from '@material-ui/icons/ArrowDownward';
import React from 'react';
import UpIcon from '@material-ui/icons/ArrowUpward';

import QuestionWidget, { QuestionSettings } from 'components/EventCreateUpdateForm/QuestionsWidget/QuestionWidget';

interface Props {
  disabled: boolean;
  hasMany: boolean;
  isFirst: boolean;
  isLast: boolean;
  onChange: (value: QuestionSettings) => void;
  onDelete: () => void;
  onMoveDown: () => void;
  onMoveUp: () => void;
  value: QuestionSettings;
}

const QuestionsWidgetWrapper = (props: Props) => {
  const { disabled, hasMany, isFirst, isLast, onChange, onDelete, onMoveDown, onMoveUp, value } = props;
  return (
    <div className="relative">
      {hasMany && (
        <div className="absolute top-1 right-1 hstack-thin">
          <ActionButton onClick={onMoveUp} disabled={isFirst}>
            <UpIcon />
          </ActionButton>
          <ActionButton onClick={onMoveDown} disabled={isLast}>
            <DownIcon />
          </ActionButton>
          <ActionButton onClick={onDelete} disabled={disabled}>
            <DeleteIcon />
          </ActionButton>
        </div>
      )}
      <QuestionWidget value={value} disabled={disabled} onChange={onChange} attrs={{}} />
    </div>
  );
};
export default QuestionsWidgetWrapper;
